import { Pipe, PipeTransform } from '@angular/core';
import { States } from '../model/states/states';

@Pipe({
    name: 'state'
})
export class StatePipe implements PipeTransform {
    transform(value: string, ...args: any[]): string {
        const state = States.getStates(['US', 'CA', 'MX']).find(valueState => valueState.abbreviation === value);
        return  `${state.abbreviation} - ${state.name}`;
    }
}
