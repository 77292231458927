import { Injectable } from '@angular/core';
import { Envelope } from '@xpo-ltl/api-common';
import {
  GetServiceCenterDetailsResp,
  IsPndAvailableResp,
  GetLocReferenceDetailsBySicResp,
} from '@xpo-ltl/sdk-location';
import { BaseService } from '../base-service';
import { DataApiService } from '../data-api/data-api.service';
import { DataOptions } from '../data-api/data-options';
import { PostalAssignmentsRequest } from './model/postal-assignments-request';
import { PostalAssignmentsResponse } from './model/postal-assignments-response';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';
import { HttpClient } from '@angular/common/http';
import { catchError, timeout } from 'rxjs/operators';

@Injectable()
export class LocationApiService extends BaseService {
  private serviceCenterLocationCache = {};

  constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService, private _http: HttpClient) {
    super();
  }

  protected getEndPoint(): string {
    return this.configManager.getSetting(ConfigManagerProperties.locationApiEndpoint);
  }

  getPostalAssignments(
    request: PostalAssignmentsRequest,
    showOverlay?: boolean,
    showErrorToast?: boolean
  ): Promise<PostalAssignmentsResponse> {
    if (showOverlay === undefined) {
      showOverlay = true;
    }
    if (showErrorToast === undefined) {
      showErrorToast = true;
    }
    return new Promise((resolve, reject) => {
      this.dataApiService
        .get(
          this.getUrl('postalassignments'),
          request,
          <DataOptions>{
            loadingOverlayEnabled: showOverlay,
            toastOnError: showErrorToast,
          },
          null
        )
        .then(
          (response: Envelope) => {
            resolve(response['data'] as PostalAssignmentsResponse);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getServiceCenterLocation(
    sicCd: string,
    showOverlay?: boolean,
    showErrorToast?: boolean
  ): Promise<GetServiceCenterDetailsResp> {
    if (showOverlay === undefined) {
      showOverlay = true;
    }
    if (showErrorToast === undefined) {
      showErrorToast = true;
    }
    return new Promise((resolve, reject) => {
      if (this.serviceCenterLocationCache[sicCd]) {
        resolve(this.serviceCenterLocationCache[sicCd]);
      } else {
        const dataOptions = new DataOptions();
        dataOptions.loadingOverlayEnabled = showOverlay;
        dataOptions.toastOnError = showErrorToast;
        this.dataApiService.get(this.getUrl(`service-centers/${sicCd}`), null, dataOptions, null).then(
          (response: Envelope) => {
            this.serviceCenterLocationCache[sicCd] = response['data'] as GetServiceCenterDetailsResp;
            resolve(this.serviceCenterLocationCache[sicCd]);
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  }

  /**
   * This service will check the delivery date at the destination SIC to see if that day is a service day.
   * Pre-conditions:
   * Input data are valid. A valid destination SIC and delivery date.
   *
   * Post-conditions:
   * Return true if PND is available and false otherwise.
   *
   * Basic Usage:
   * isPndAvailable("UPO", new Date('2017-12-24')).then(
   *     response => { },
   *     error => {}
   * )
   * @param {string} destSic
   * @param {Date} estimatedDeliveryDt
   * @returns {Promise<IsPndAvailableResp>}
   */
  isPndAvailable(destSic: string, estimatedDeliveryDt: Date): Promise<IsPndAvailableResp> {
    const dataOptions = new DataOptions();
    dataOptions.loadingOverlayEnabled = false;
    return new Promise((resolve, reject) => {
      const estimatedDeliveryDate = estimatedDeliveryDt.toISOString().split('T')[0];
      this.dataApiService
        .get(this.getUrl(`/pnd-availability/${destSic}/${estimatedDeliveryDate}`), null, dataOptions, null)
        .then((response: Envelope) => {
          resolve(response['data'] as IsPndAvailableResp);
        });
    });
  }

  /** To get the parent sic details for a given sic -- to cover for cases of satellite sic*/
  getLocationReferenceDetailsBySic(
    sicCd: string,
    showOverlay?: boolean,
    showErrorToast?: boolean
  ): Promise<GetLocReferenceDetailsBySicResp> {
    if (showOverlay === undefined) {
      showOverlay = true;
    }
    if (showErrorToast === undefined) {
      showErrorToast = true;
    }
    return new Promise((resolve, reject) => {
      const dataOptions = new DataOptions();
      dataOptions.loadingOverlayEnabled = showOverlay;
      dataOptions.toastOnError = showErrorToast;
      this.dataApiService.get(this.getUrl(`loc-reference-sics/${sicCd}`), null, dataOptions, null).then(
        (response: Envelope) => {
          resolve(response['data'] as GetLocReferenceDetailsBySicResp);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  // Get billTo response according to customer 2.o calling
  verifyPostalCodeForLotus(postalCds, stateCds, countryCds?) {
    let query = ""
    if(postalCds){
      var esc = encodeURIComponent;
      query +=  Object.keys(postalCds)
      .map(k => 'postalCds=' + esc(postalCds[k]))
      .join('&');
    }
    if(countryCds){
      var esc = encodeURIComponent;
      query +=  Object.keys(postalCds)
      .map(k => '&countryCds=' + esc(countryCds[k])).join('')
    }
    if(stateCds){
      var esc = encodeURIComponent;
      query +=  Object.keys(postalCds)
      .map(k => '&stateCds=' + esc(stateCds[k])).join('')
    }
      return this._http.get(`${this.configManager.getSetting(ConfigManagerProperties.apiUrl)}${this.configManager.getSetting(ConfigManagerProperties.locationV2ApiEndpoint)}postal-assignments?${query}`).pipe(timeout(75000), catchError((error) => {
      throw error;
    }));
  }
}
