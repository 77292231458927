export class PostalAssignmentsRequest {
  activeFlag: boolean;
  postalCodes: Array<string> = new Array<string>();
  returnHostSicInd: boolean;
  countrySubdivisionCds:Array<string> = new Array<string>();

  addPostalCode(code: string): PostalAssignmentsRequest {
    this.postalCodes.push(code);
    return this;
  }
  setActiveFlag(active: boolean): PostalAssignmentsRequest {
    this.activeFlag = active;
    return this;
  }

  setHostSicInd(active: boolean): PostalAssignmentsRequest {
    this.returnHostSicInd = active;
    return this;
  }

  addCountrySubdivisionCode(code: string): PostalAssignmentsRequest {
    this.countrySubdivisionCds.push(code);
    return this;
  }
}
